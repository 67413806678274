<template>
  <!-- Loading circle -->
  <div
    v-if="loading"
    class="d-flex justify-center"
  >
    <v-progress-circular
      :size="50"
      indeterminate
      color="primary"
    >
    </v-progress-circular>
  </div>
  <div v-else>
    <!-- Card Title -->
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        md="12"
        class="align-self-end mb-sm-2 mt-2"
      >
        <v-card
          class="ps-3 mx-1 rounded-xl"
          flat
        >
          <v-row>
            <v-col
              cols="12"
              lg="12"
              sm="11"
              md="9"
              class="py-0"
            >
              <v-card-title class="page-title flex-nowrap text-2xl mt-0 pt-2 px-1 align-center">
                <div class="d-flex align-center">
                  <v-avatar
                    size="40"
                    class="v-avatar-light-bg primary--text"
                  >
                    <v-icon
                      size="25"
                      color="primary"
                    >
                      {{ icons.mdiMessageProcessingOutline }}
                    </v-icon>
                  </v-avatar>

                  <span class="text-no-wrap font-weight-bold mx-1">
                    {{ $t('MessageTemplates') }}
                  </span>
                </div>
              </v-card-title>
              <v-card-text class="pt-1 pb-0">
                <v-row
                  v-for="(item,index) in $t('MessageTemplatesExplain')"
                  :key="index"
                >
                  <div class="pb-1 d-flex">
                    <div>
                      <v-icon
                        class="me-1"
                        color="primary"
                        size="25"
                      >
                        {{ titleIcons[index] }}
                      </v-icon>
                    </div>
                    <div>
                      <span>
                        {{ item }}
                      </span>
                    </div>
                  </div>
                </v-row>
                <v-row>
                  <div
                    class="d-flex"
                    :class="$vuetify.breakpoint.smAndDown || $vuetify.breakpoint.lgAndUp ? 'pb-0' : 'pb-5'"
                  >
                    <div>
                      <v-icon
                        class="me-1"
                        color="primary"
                        size="25"
                      >
                        {{ icons.mdiLinkVariant }}
                      </v-icon>
                    </div>
                    <div>
                      <span>
                        <span>
                          {{ $t('InvitationPreviewExp') }}
                        </span>
                        <router-link
                          :to="{name: 'invitation' }"
                        >
                          {{ $t('ViewInvitation') }}
                        </router-link>
                      </span>
                    </div>
                  </div>
                </v-row>
              </v-card-text>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="3"
            >
              <div>
                <v-img
                  width="140"
                  src="@/assets/images/3d-characters/pose-fs-9.png"
                  class="gamification-john-pose-2"
                ></v-img>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Messages template -->
    <v-row
      justify="center"
      align="center"
      class="mt-10"
    >
      <v-col
        cols="12"
        md="9"
        sm="7"
        lg="7"
        class="mx-0"
      >
        <v-card
          v-show="Object.keys(langMsg || {}).length > 0"
          class="ma-5 mx-auto mt-0 rounded-xl"
          flat
          tile
        >
          <v-tabs
            v-model="currentTabLang"
            show-arrows
            center-active
            mobile-breakpoint="1"
            fixed-tabs
            centered
          >
            <v-tab
              v-for="(value, index) in langMsg"
              :key="index"
            >
              {{ $t(value.lang) }}
            </v-tab>
          </v-tabs>
          <v-row>
            <v-col
              cols="12"
              md="5"
              class="mt-4 ml-0"
            >
              <v-tabs
                v-model="currentTabMessage"
                vertical
              >
                <v-tab
                  class="justify-start"
                >
                  <v-icon
                    right
                    class="me-1"
                  >
                    {{ icons.mdiMessageTextOutline }}
                  </v-icon>
                  {{ $t('invitationMessage') }}
                </v-tab>

                <v-tab class="justify-start">
                  <v-icon
                    right
                    class="me-1"
                  >
                    {{ icons.mdiMessageAlertOutline }}
                  </v-icon>
                  {{ $t('DirectionMessage') }}
                </v-tab>
                <v-tab class="justify-start">
                  <v-icon
                    right
                    class="me-1"
                  >
                    {{ icons.mdiHandHeartOutline }}
                  </v-icon>
                  {{ $t('ThanksMsg') }}
                </v-tab>
              </v-tabs>
            </v-col>
            <v-col
              cols="12"
              md="7"
              class="mt-6"
            >
              <v-tabs-items
                v-model="currentTabLang"
              >
                <v-tab-item
                  v-for="(msg, index) in langMsg"
                  :key="index"
                >
                  <v-tabs-items
                    v-model="currentTabMessage"
                  >
                    <v-tab-item
                      class="justify-center align-center pa-2"
                    >
                      <EmojiPicker
                        :message-default="msg.messages[currentTabMessage]"
                        :lang="index"
                        @changed="(message) => messageHandler(index, message)"
                      ></EmojiPicker>
                    </v-tab-item>
                    <v-tab-item class="justify-center align-center pa-2">
                      <EmojiPicker
                        :message-default="msg.messages[currentTabMessage]"
                        :lang="index"
                        @changed="(message) => messageHandler(index, message)"
                      ></EmojiPicker>
                    </v-tab-item>
                    <v-tab-item class="justify-center align-center pa-2">
                      <EmojiPicker
                        :message-default="msg.messages[currentTabMessage]"
                        :lang="index"
                        @changed="(message) => messageHandler(index, message)"
                      ></EmojiPicker>
                    </v-tab-item>

                    <v-card-actions
                      class="justify-center align-center"
                    >
                      <v-btn
                        color="primary"
                        class="me-2"
                        rounded
                        large
                        @click="(saveMsg(index))"
                      >
                        {{ $t('Saving') }}
                      </v-btn>
                    </v-card-actions>
                  </v-tabs-items>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- Iphone preview -->
      <v-col
        cols="12"
        md="3"
        sm="3"
        lg="3"
        style="display:flex;"
      >
        <div class="mx-auto">
          <device
            v-show="Object.keys(langMsg || {}).length > 0"
            v-bind="$data"
          >
            <div
              class="container"
            >
              <div
                class="deviceMessage"
                dir="auto"
              >
                {{ activeMessageDisplay }}
              </div>
            </div>
          </device>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { i18n } from '@/plugins/i18n/index'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import {
  mdiCommentEditOutline,
  mdiHandHeartOutline,
  mdiHeartOutline,
  mdiLinkVariant,
  mdiMessageAlertOutline,
  mdiMessageProcessingOutline,
  mdiMessageTextClockOutline,
  mdiMessageTextOutline,
} from '@mdi/js'
import { computed, ref, set } from '@vue/composition-api'
import device from 'vue-and-devices-mocks'

// import 'vue-push-notification-preview/src/assets/devices.scss'
import EmojiPicker from '@/components/Common/EmojiPicker.vue'
import Vue from 'vue'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    device,
    EmojiPicker,
  },

  data() {
    return {
      type: 'iphone',
      scale: 12,
    }
  },

  setup() {
    const currentTabLang = ref(-1)
    const currentTabMessage = ref(0)
    const langMsg = ref()
    const loading = ref(true)
    const toast = useToast()
    const showToast = msg => toast.error(i18n.t(msg))

    const titleIcons = [mdiHeartOutline, mdiCommentEditOutline, mdiLinkVariant]

    // Get saved Messages
    const getMessages = () => {
      store
        .dispatch('getMessageTemplate', { id: store.state.eventData.id })
        .then(response => {
          const messagesArray = response.data
          const mappedMessages = messagesArray.reduce((carry, val) => {
            const carryMessages = carry[val.lang] ? carry[val.lang].messages : []
            carryMessages[val.type - 1] = val.message

            return {
              ...carry,
              [val.lang]: {
                lang: val.lang,
                messages: [...carryMessages],
              },
            }
          }, {})

          langMsg.value = mappedMessages
          currentTabLang.value += Object.keys(langMsg.value || {}).length
        })
        .catch(() => showToast('errorManageEvents'))
        .finally(() => {
          loading.value = false
        })
    }
    getMessages()

    const saveMsg = item => {
      loading.value = true
      const currLangObject = langMsg.value[item]
      if (currLangObject.messages[currentTabMessage.value].length < 20) {
        loading.value = false
        showToast(i18n.t('MinField', { num: '20' }))
      } else {
        const sendToServer = Object.keys(langMsg.value).reduce((carried, lang) => {
          currLangObject.messages.map((message, index) => ({
            lang,
            message,
            type: index + 1,
          }))

          return [
            ...currLangObject.messages.map((message, index) => ({
              lang: item,
              message,
              type: index + 1,
            })),
          ]
        }, [])
        const userMessages = { templates: sendToServer }

        store
          .dispatch('editMessageTemplate', { id: store.state.eventData.id, msgData: userMessages })
          .then(() => {
            // Track successful registration
            Vue.prototype.$gtag.event('Message_Template_Changed', {
              event_category: 'message template',
              event_label: 'Template Edit Success',
            })
          })
          .catch(() => showToast('errorManageEvents'))
          .finally(() => {
            loading.value = false
          })
      }
    }

    // Update the langMsg when changing
    const messageHandler = (lang, message) => {
      set(langMsg.value[lang].messages, currentTabMessage.value, message)
    }

    // Display message on iphone device on real time
    const activeMessageDisplay = computed(() => {
      if (Object.keys(langMsg.value || {}).length === 0) {
        return ''
      }
      const activeLangTabKey = Object.keys(langMsg.value)[currentTabLang.value]

      const linkRSVP = 'https://anita-lee.com/i/J41Sjh'
      const linkWaze = 'https://anita-lee.com/w/1415jh'
      const messageBeforeRsvpLink = i18n.t('ForRsvp', activeLangTabKey)
      const messageBeforeDirectionLing = i18n.t('ForDirection', activeLangTabKey)
      const messageBeforeLing = ref('')

      const getMessageBeforeLing = () => {
        // RSVP message
        if (currentTabMessage.value === 0) {
          messageBeforeLing.value = `${messageBeforeRsvpLink} ${linkRSVP}`
        }

        // Reminder & Direction message
        if (currentTabMessage.value === 1) {
          messageBeforeLing.value = `${messageBeforeDirectionLing} ${linkWaze}`
        }
      }
      getMessageBeforeLing()

      return `${langMsg.value[activeLangTabKey].messages[currentTabMessage.value]} \n${messageBeforeLing.value}`
    })

    return {
      activeMessageDisplay,
      currentTabLang,
      currentTabMessage,
      langMsg,
      saveMsg,
      messageHandler,
      loading,
      titleIcons,

      icons: {
        mdiMessageProcessingOutline,
        mdiMessageTextOutline,
        mdiMessageTextClockOutline,
        mdiMessageAlertOutline,
        mdiHandHeartOutline,
        mdiLinkVariant,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';
.emoji-wysiwyg-editor {
  border-radius: 10px !important;
  padding-right: 6px;
  padding: 17px;
}
.emoji-wysiwyg-editor:focus {
  outline: var(--v-primary-base);
  border: 2px solid var(--v-primary-base) !important;
}

.device__container .device {
  border: 2px solid var(--v-primary-base) !important;
}
.container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.deviceMessage {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
  width: 177px;
  font-size: 13px;
  white-space: pre-line;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 305px;
}

.container .deviceMessage {
  color: white;
  margin: -10px;
  top: -20px;
  background: #00994c;
  position: relative;
}
.container .deviceMessage:before {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: #00994c;
  border-bottom-left-radius: 15px;
}

.container .deviceMessage:after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: #00994c;
  border-bottom-left-radius: 10px;
}

.gamification-john-pose-2 {
  position: absolute;
}

.gamification-john-pose-2 {
  bottom: 0;
  @include ltr() {
    right: 2%;
  }
  @include rtl() {
    left: 2%;
  }
}
@media (max-width: 800px) {
  .gamification-john-pose-2 {
    max-width: 90px;
    bottom: 0;
    @include ltr() {
      right: 0%;
    }
    @include rtl() {
      left: 0%;
    }
  }
}

@media (max-width: 600px) {
  .gamification-john-pose-2 {
    display: none;
  }
}

@media (max-width: 400px) {
  .page-title {
    font-size: 1.2rem !important;
  }
}
</style>
